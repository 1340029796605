<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" key="sign-up">
      <form class="sidebar-register-form" autocomplete="off" @submit.prevent>
        <div class="sidebar-register-form__gender">
          <CustomRadio
            :label="$t('Woman')"
            value="2"
            @radioChange="(e) => (form.gender = e)"
            :selected="form.gender"
            name="form-gender"
          />
          <CustomRadio
            :label="$t('Man')"
            value="1"
            @radioChange="(e) => (form.gender = e)"
            :selected="form.gender"
            name="form-gender"
          />
        </div>
        <CustomInput
          @inputChange="(e) => (form.email = e)"
          input-name="email"
          input-id="register-email"
          :input-label="$t('Your email')"
          input-placeholder=""
          full-width
          validate-rule="required|email"
          interaction-mode="eager"
        />
        <CustomInput
          @inputChange="(e) => (form.firstName = e)"
          input-name="first-name"
          input-id="first-name"
          :input-label="$t('First Name')"
          input-placeholder=""
          full-width
          validate-rule="required|alpha_spaces"
          interaction-mode="eager"
        />
        <CustomInput
          @inputChange="(e) => (form.lastName = e)"
          input-name="last-name"
          input-id="last-name"
          :input-label="$t('Last Name')"
          input-placeholder=""
          full-width
          validate-rule="required|alpha_spaces"
          interaction-mode="eager"
        />
        <CustomInput
          @inputChange="(e) => handleDateOfBirth(e)"
          input-name="date_of_birth"
          input-id="date_of_birth"
          :input-label="$t('Date of birth')"
          input-placeholder=""
          input-type="date"
          full-width
          interaction-mode="eager"
          :class="!userHasLegalAge && 'date-input-error'"
        />
        <div v-if="!userHasLegalAge" class="date-error">
          {{ $t('Registration is reserved for users over 18 years of age') }}
        </div>
        <CustomPhoneNumberInput
          @inputChange="(e) => (form.phone_number = e)"
          @validityChange="(e) => (validPhone = e)"
          input-id="phone"
          :input-label="$t('Phone')"
        />
        <div class="password-container">
          <CustomInput
            @inputChange="(e) => (form.password = e)"
            input-name="password"
            input-id="password"
            input-type="password"
            :input-label="$t('Password')"
            input-placeholder=""
            full-width
            class="password-input"
            :class="!passwordsMatch && 'password-input-error'"
            validate-rule="required|min:8"
          />
          <CustomInput
            @inputChange="(e) => (form.repassword = e)"
            input-name="repassword"
            input-id="repassword"
            input-type="password"
            :input-label="$t('Repeat Password')"
            input-placeholder=""
            full-width
            class="password-input password-input-confirm"
            :class="!passwordsMatch && 'password-input-error'"
            validate-rule="required"
          />
          <div
            v-if="!passwordsMatch && form.password.length >= 8"
            class="password-error"
          >
            {{ $t('The passwords must match.') }}
          </div>
        </div>
        <CustomCheckbox
          @checkboxChange="(e) => (form.consents.privacy = e)"
          validate-rule="required"
        />
        <CustomCheckbox
          @checkboxChange="(e) => (form.consents.newsletter = e)"
          name="newsletter"
          :label="
            $t(
              'I agree with the handling of my data by this website in order to receive commercial or promotional communications through a newsletter'
            )
          "
        />
        <CustomCheckbox
          @checkboxChange="(e) => (form.consents.marketing = e)"
          name="marketing"
          :label="
            $t(
              'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers.'
            )
          "
        />
        <CustomButton
          :disabled="
            loading ||
            invalid ||
            !passwordsMatch ||
            !validPhone ||
            !userHasLegalAge
          "
          :text="$t('Create an account')"
          type="primary"
          @click="handleRegister"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  computed,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import {
  CustomInput,
  CustomButton,
  CustomCheckbox,
  CustomRadio,
  CustomPhoneNumberInput,
} from '~/components/General/';
import { useUser, useCountrySearch } from '@gemini-vsf/composables';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useRecaptcha } from '~/composables/useRecaptcha';
import { isLegalAgeFromBirthday } from '~/helpers/dateHelper';

extend('email', {
  ...email,
  message: 'Invalid email',
});
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});
extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'RegisterForm',
  components: {
    ValidationObserver,
    CustomInput,
    CustomButton,
    CustomCheckbox,
    CustomRadio,
    CustomPhoneNumberInput,
  },
  props: {
    isRecaptchaEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isSubscribed = ref(true);
    const validPhone = ref(true);
    const error = reactive({
      register: null,
    });

    const { $recaptcha } = useRecaptcha();

    const { register, loading, error: userError } = useUser();
    const form = ref({
      gender: null,
      firstName: null,
      lastName: null,
      email: null,
      date_of_birth: null,
      password: null,
      repassword: null,
      phone_number: null,
      consents: [{ privacy: null }, { newsletter: null }, { marketing: null }],
    });
    const passwordsMatch = computed(
      () => form.value.password === form.value.repassword
    );

    const resetErrorValues = () => {
      error.register = null;
    };

    const { load: loadCountries, countries } = useCountrySearch();

    const parseCountries = (countriesArray) => {
      return countriesArray
        .map((e) => {
          return {
            value: e.two_letter_abbreviation,
            label: e.full_name_locale,
          };
        })
        .sort((a, b) => {
          if (a.value === 'ZZ') return 1;
          if (b.value === 'ZZ') return -1;
          return a.label.localeCompare(b.label);
        });
    };

    const parsedCountries = ref([]);

    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();

    onBeforeMount(async () => {
      await loadCountries();
      parsedCountries.value = parseCountries(countries.value);
    });

    onMounted(async () => {
      if (props.isRecaptchaEnabled) {
        await $recaptcha.init();
      }
    });

    onBeforeUnmount(() => {
      if (props.isRecaptchaEnabled) {
        $recaptcha.destroy();
      }
    });

    const handleRegister = async () => {
      resetErrorValues();

      const dateOfBirth = form.value?.date_of_birth
        ? new Date(form.value.date_of_birth).toJSON()
        : null;

      if (props.isRecaptchaEnabled) {
        const recaptchaToken = await $recaptcha.execute('register');

        await register({
          user: {
            email: form.value.email,
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            password: form.value.password,
            repassword: form.value.repassword,
            is_subscribed: form.value?.consents?.newsletter,
            date_of_birth: dateOfBirth,
            phone_number: form.value.phone_number,
            consents: [
              {
                consent_type: 'MARKETING',
                consent_value: form.value?.consents?.marketing || false,
              },
              {
                consent_type: 'PRIVACY',
                consent_value: form.value?.consents?.privacy || true,
              },
              {
                consent_type: 'TERMS_AND_CONDITIONS',
                consent_value: form.value?.consents?.privacy || true,
              },
              {
                consent_type: 'CUSTOM',
                consent_type_custom: 'NEWSLETTER',
                consent_value: form.value?.consents?.newsletter || false,
              },
            ],
            recaptchaToken,
          },
        });
      } else {
        await register({
          user: {
            email: form.value.email,
            firstName: form.value.firstName,
            lastName: form.value.lastName,
            password: form.value.password,
            repassword: form.value.repassword,
            is_subscribed: form.value?.consents?.newsletter,
            gender: !form.value.gender
              ? 'O'
              : form.value.gender === '1'
              ? 'M'
              : 'F',
            date_of_birth: dateOfBirth,
            phone_number: form.value.phone_number,
            consents: [
              {
                consent_type: 'MARKETING',
                consent_value: form.value?.consents?.marketing || false,
              },
              {
                consent_type: 'PRIVACY',
                consent_value: form.value?.consents?.privacy || true,
              },
              {
                consent_type: 'TERMS_AND_CONDITIONS',
                consent_value: form.value?.consents?.privacy || true,
              },
              {
                consent_type: 'CUSTOM',
                consent_type_custom: 'NEWSLETTER',
                consent_value: form.value?.consents?.newsletter || false,
              },
            ],
          },
        });
      }
      const hasUserErrors = userError.value.register;
      if (hasUserErrors) {
        error.register = userError.value.register?.message;
        sendNotification({
          id: Symbol('register_failed'),
          message: trans.t(
            'There was an error while trying to sign you up. Please try again.'
          ),
          type: 'danger',
          persist: true,
          icon: 'error',
          title: 'Registration Error',
        });
      } else {
        emit('loginEvent');
      }
    };

    const userHasLegalAge = ref(true);
    const handleDateOfBirth = (e) => {
      userHasLegalAge.value = e ? isLegalAgeFromBirthday(e) : true;
      form.value.date_of_birth = e;
    };

    return {
      isSubscribed,
      register,
      error,
      loading,
      form,
      validPhone,
      userHasLegalAge,
      parsedCountries,
      passwordsMatch,
      handleRegister,
      handleDateOfBirth,
    };
  },
});
</script>

<style lang="scss" scoped>
.sidebar-register-form {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;

  &__gender {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>

<style lang="scss">
.sidebar-register-form {
  .custom-select__container {
    width: 100%;
  }
}
.password-container {
  .password-input-error {
    input {
      outline-color: var(--c-red-error);
    }
  }
  .password-error {
    @include font-12x16;
    padding-left: 1rem;
    margin-top: 1.5rem;
    color: var(--c-red-error);
  }
  .password-input-confirm {
    margin-top: var(--space-xs);
  }
}
.custom-input-date.date-input-error {
  .custom-input-container {
    input.custom-input-input {
      outline-color: var(--c-red-error);
    }
  }
}
.date-error {
  @include font-12x16;
  margin-top: -1.5rem;
  color: var(--c-red-error);
}
</style>
