<!-- eslint-disable vue/no-deprecated-destroyed-lifecycle -->
<template>
  <ValidationObserver class="reset-password" key="log-in">
    <form class="reset-password__form" @submit.prevent>
      <CustomInput
        @inputChange="(e) => (userEmail = e)"
        input-name="email"
        input-id="register-email"
        :input-label="$t('Your email')"
        input-placeholder=""
        full-width
        validate-rule="required|email"
      />
      <div v-if="forgotPasswordError.request">
        <p class="reset-password__paragraph">
          {{
            $t(
              'It was not possible to request a new password, please check the entered email address.'
            )
          }}
        </p>
      </div>
      <CustomButton
        type="primary"
        :disabled="forgotPasswordLoading"
        :text="$t('Reset Password')"
        @click="handleForgotten(userEmail)"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { CustomInput, CustomButton } from '~/components/General/';
import { ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { useForgotPassword } from '@gemini-vsf/composables';
import { useRecaptcha } from '~/composables/useRecaptcha';

extend('email', {
  ...email,
  message: 'Invalid email',
});
extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'ForgottenForm',
  components: { CustomInput, ValidationObserver, CustomButton },
  props: {
    isRecaptchaEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const userEmail = ref();
    const {
      request: forgotPasswordRequest,
      error: forgotPasswordError,
      loading: forgotPasswordLoading,
    } = useForgotPassword();

    const { $recaptcha } = useRecaptcha();

    onMounted(async () => {
      if (props.isRecaptchaEnabled) {
        await $recaptcha.init();
      }
    });

    onBeforeUnmount(() => {
      if (props.isRecaptchaEnabled) {
        $recaptcha.destroy();
      }
    });

    const handleForgotten = async () => {
      if (props.isRecaptchaEnabled) {
        const recaptchaToken = await $recaptcha.execute('forgotten');
        await forgotPasswordRequest({ email: userEmail.value, recaptchaToken });
      } else {
        await forgotPasswordRequest({ email: userEmail.value });
      }

      if (!forgotPasswordError.value.request) {
        emit('sentForgottenPasswordRequest');
      }
    };

    return {
      userEmail,
      forgotPasswordError,
      handleForgotten,
      forgotPasswordLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset-password {
  &__form {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
  }
  &__paragraph {
    @include font-16x16;
  }
}
</style>
