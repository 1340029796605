<template>
  <div class="sf-sidebar__bottom__container">
    <p class="text">
      {{ $t('Have an account already?') }}
    </p>
    <CustomButton
      class="sf-button--text"
      @click="$emit('showLogin')"
      :text="$t('Login')"
      type="secondary"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomButton } from '~/components/General/';

export default defineComponent({
  name: 'ShowLogin',
  components: { CustomButton },
  props: {},
  setup() {},
});
</script>

<style lang="scss">
.sf-sidebar__bottom__container {
  .text {
    margin: 0 0 1.563rem;
    @include font-14x19;
  }
}
</style>
