<template>
  <div class="login-sidebar">
    <SfSidebar
      :visible="isLoginModalOpen"
      position="right"
      @close="closeModal"
      title="Account"
      :subtitle="$t('Log in your account:')"
    >
      <template #bar> <span /> </template>
      <template #title> <span /> </template>
      <template #circle-icon> <span /> </template>

      <template #content-top>
        <div class="login-sidebar__title">
          <span class="login-sidebar__title__text"> Account </span>
          <CrossIcon class="login-sidebar__title__icon" @click="closeModal" />
        </div>
        <div class="login-sidebar__subtitle">
          <span v-if="isLogin">{{ $t('Log in your account:') }}</span>
          <span v-else-if="isForgotten">{{ $t('Forgot Password') }}</span>
          <span v-else-if="isThankYouAfterForgotten"></span>
          <span v-else>{{ $t('Create an account:') }}</span>
        </div>
      </template>

      <div v-if="isLogin">
        <LoginForm
          :is-recaptcha-enabled="isRecaptchaEnabled"
          @loginEvent="handleLoginEvent"
          @showForgottenPassword="setIsForgottenValue(true)"
        />
      </div>
      <div v-else-if="isForgotten">
        <ForgottenForm
          @sentForgottenPasswordRequest="handleForgotten"
          :is-recaptcha-enabled="isRecaptchaEnabled"
        />
      </div>
      <div v-else-if="isThankYouAfterForgotten" class="thank-you">
        <ForgottenThanks :user-email="userEmail" />
      </div>
      <div v-else>
        <RegisterForm
          :is-recaptcha-enabled="isRecaptchaEnabled"
          @loginEvent="handleLoginEvent"
        />
      </div>

      <template #content-bottom>
        <ShowRegister
          v-if="isLogin || isForgotten"
          @showRegister="
            () => {
              setIsForgottenValue(false);
              setIsLoginValue(false);
            }
          "
        />
        <ShowLogin v-else @showLogin="setIsLoginValue(true)" />
      </template>
    </SfSidebar>
  </div>
</template>

<script>
import {
  ref,
  watch,
  useRoute,
  defineComponent,
  computed,
  useContext,
  useRouter,
  onMounted,
} from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';
import { useUser } from '@gemini-vsf/composables';
import {
  useUiState,
  useWindow,
  useWishlist,
  useGtm,
  useKlaviyo,
} from '~/composables';
import { removeItem } from '~/helpers/asyncLocalStorage';
import LoginForm from '~/components/MyAccount/LoginForm.vue';
import ForgottenForm from '~/components/MyAccount/ForgottenForm.vue';
import ForgottenThanks from '~/components/MyAccount/ForgottenThanks.vue';
import RegisterForm from '~/components/MyAccount/RegisterForm.vue';
import ShowRegister from '~/components/MyAccount/ShowRegister.vue';
import ShowLogin from '~/components/MyAccount/ShowLogin.vue';
import { CrossIcon } from '~/components/General/Icons';
import { useRecaptcha } from '~/composables/useRecaptcha';

export default defineComponent({
  name: 'LoginSidebar',
  components: {
    SfSidebar,
    LoginForm,
    ForgottenForm,
    ForgottenThanks,
    RegisterForm,
    ShowRegister,
    CrossIcon,
    ShowLogin,
  },
  setup() {
    const { isLoginModalOpen, toggleLoginModal } = useUiState();
    const { handleBodyClass } = useWindow();
    const { customerGtmPush } = useGtm();
    const { identifyKlaviyo } = useKlaviyo();

    const isSubscribed = ref(true);
    const isLogin = ref(true);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);

    const userEmail = ref('');

    const { isEnabled: isRecaptchaEnabled, $recaptcha } = useRecaptcha();

    const { app } = useContext();
    const router = useRouter();
    const { user } = useUser();
    const { loadWishlist } = useWishlist();
    const isLoggedIn = ref(false);
    const route = useRoute();

    watch(isLoginModalOpen, () => {
      if (isSubscribed.value === false) {
        isSubscribed.value = true;
      }
    });

    const setIsLoginValue = (value) => {
      isLogin.value = value;
    };

    const setIsForgottenValue = (value) => {
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const closeModal = () => {
      setIsForgottenValue(false);
      setIsLoginValue(true);
      toggleLoginModal();
      handleBodyClass('removeClass', 'open_menu');
    };

    const hasLoggedIn = computed(() => {
      if (isLoggedIn && isLoggedIn.value) {
        return isLoggedIn.value;
      }
      return false;
    });

    watch(isLoggedIn, () => {
      if (isLoggedIn.value) {
        hasLoggedIn.value = true;
        return hasLoggedIn.value;
      }
      return false;
    });

    const handleLoginEvent = async () => {
      isLoggedIn.value = true;
      setIsLoginValue(true);
      toggleLoginModal();
      customerGtmPush();
      identifyKlaviyo();

      await removeItem('checkout');
      await loadWishlist({
        pageSize: 48,
        customQuery: {
          wishlist: 'wishlistCustom',
        },
      });

      if (isRecaptchaEnabled.value) $recaptcha.reset();

      if (route.value.fullPath.includes('checkout')) {
        router.push(app.$fixUrlForCurrentLocale('/checkout/shipping'));
      } else {
        router.push(app.$fixUrlForCurrentLocale('/my-account'));
      }
    };

    const handleForgotten = (email) => {
      isThankYouAfterForgotten.value = true;
      isForgotten.value = false;
      userEmail.value = email;
    };

    onMounted(() => {
      if (route?.value?.query?.register) {
        toggleLoginModal();
        isLogin.value = false;
      }
    });

    return {
      closeModal,
      handleForgotten,
      isForgotten,
      isLogin,
      isLoginModalOpen,
      isSubscribed,
      isThankYouAfterForgotten,
      setIsForgottenValue,
      setIsLoginValue,
      userEmail,
      hasLoggedIn,
      isLoggedIn,
      isRecaptchaEnabled,
      user,
      toggleLoginModal,
      handleLoginEvent,
      recaptchaInstance: $recaptcha,
    };
  },
});
</script>

<style lang="scss">
.login-sidebar {
  .sf-sidebar {
    &__aside {
      z-index: 4;
    }
    &__top {
      padding: var(--space-s);
      .login-sidebar {
        &__title {
          @include font-16x16;
          display: flex;
          justify-content: space-between;
          padding-bottom: var(--space-s);
          &__icon {
            &:hover {
              cursor: pointer;
            }
          }
        }
        &__subtitle {
          @include font-14x19;
        }
      }
    }
    &__content {
      padding: 0 1.563rem;
      height: fit-content;
      padding-bottom: 170px;
    }
    &__bottom {
      position: absolute;
      top: unset;
      bottom: 0;
      width: 100%;
      padding: var(--space-s) 1.563rem;
      background-color: var(--c-super-light-grey);
      &:has(> .hide-bottom) {
        display: none;
      }
    }
  }
}
</style>
