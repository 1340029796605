<template>
  <div>
    <i18n
      tag="p"
      class="thank-you__paragraph"
      path="forgotPasswordConfirmation"
    >
      <span class="thank-you__paragraph--bold" v-if="userEmail">{{
        userEmail
      }}</span>
    </i18n>
    <p class="thank-you__paragraph">
      {{ $t('Thank You Inbox') }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ForgottenThanks',
  components: {},
  props: {
    userEmail: {
      type: [String, null],
      default: null,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped></style>
