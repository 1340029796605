<template>
  <div class="login-sidebar__login-form">
    <ValidationObserver key="log-in" v-slot="{ valid }">
      <form class="login-sidebar__login-form__form" @submit.prevent>
        <CustomInput
          @inputChange="(e) => (form.email = e)"
          input-name="email"
          input-id="register-email"
          :input-label="$t('Your email')"
          input-placeholder=""
          full-width
          validate-rule="required|email"
          @keydown.enter="handleLogin"
        />
        <div class="password-input-container">
          <CustomInput
            @inputChange="(e) => (form.password = e)"
            input-name="password"
            input-id="login-form-password"
            :input-label="$t('Password')"
            class="login-sidebar__login-form__input"
            input-placeholder=""
            validate-rule="required"
            input-type="password"
            @keydown.enter="handleLogin"
          />
          <div class="login-sidebar__login-form__forgotten">
            <span
              class="login-sidebar__login-form__forgotten__button"
              @click="$emit('showForgottenPassword')"
              >{{ $t('Forgotten password?') }}
            </span>
          </div>
        </div>

        <CustomButton
          type="primary"
          :disabled="loading || !valid"
          :text="$t('Login')"
          @click="handleLogin"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';
import { CustomInput, CustomButton } from '~/components/General/';
import { ValidationObserver, extend } from 'vee-validate';
import {
  required,
  email as emailRule,
  confirmed,
} from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/helpers/customer/regex';
import { useUser } from '@gemini-vsf/composables';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useRecaptcha } from '~/composables/useRecaptcha';

extend('email', {
  ...emailRule,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Please make sure your passwords match',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'LoginForm',
  components: {
    CustomButton,
    CustomInput,
    ValidationObserver,
  },
  props: {
    isRecaptchaEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { login, loading, error: userError } = useUser();
    const { $recaptcha } = useRecaptcha();

    const error = reactive({
      login: null,
    });

    const form = ref({});

    const resetErrorValues = () => {
      error.login = null;
    };

    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();

    onMounted(async () => {
      if (props.isRecaptchaEnabled) {
        await $recaptcha.init();
      }
    });

    onBeforeUnmount(() => {
      if (props.isRecaptchaEnabled) {
        $recaptcha.destroy();
      }
    });

    const handleLogin = async () => {
      resetErrorValues();

      if (props.isRecaptchaEnabled) {
        const recaptchaToken = await $recaptcha.execute('login');
        await login({
          user: {
            username: form.value.email,
            password: form.value.password,
            recaptchaToken,
          },
        });
      } else {
        await login({
          user: {
            username: form.value.email,
            password: form.value.password,
          },
        });
      }

      const hasUserErrors = userError.value.login;
      if (hasUserErrors) {
        sendNotification({
          id: Symbol('login_failed'),
          message: trans.t(
            'There was an error while trying to login. Are your credentials correct?'
          ),
          type: 'danger',
          persist: false,
          icon: 'error',
          title: 'Login Error',
        });
        error.login = userError.value.login?.message;
        return;
      }

      emit('loginEvent');
    };

    return {
      login,
      error,
      form,
      loading,
      handleLogin,
    };
  },
});
</script>

<style lang="scss">
.login-sidebar__login-form {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &__input {
    width: 100% !important;
  }
  &__forgotten {
    padding-top: 0.813rem;
    &__button {
      text-decoration: underline;
      font-weight: var(--font-weight-light);
      @include font-12x16-300;
      cursor: pointer;
    }
  }
}
</style>
